.questions__form {
  padding: 40px 26px;
  display: flex;
  justify-content: space-between;
  flex: 1;

  &-content {
    &_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: inset 0px -1px 0px #f0f0f0;
    }
    &__translations {
      margin-right: 24px;
    }
  }

  &_title,
  &_addOn {
    padding: 14px 0 17px;
    margin: 0;
    font-style: normal;
    font-weight: $fontWeight-lg;
    font-size: 16px;
    line-height: 24px;
    box-shadow: inset 0px -1px 0px #f0f0f0;
    &--boxShadowNone {
      padding: 14px 0 17px;
      margin: 0;
      font-style: normal;
      font-weight: $fontWeight-lg;
      font-size: $fontSize-xs;
      line-height: 24px;
      box-shadow: none;
    }
  }
  &_subText {
    font-size: $fontSize-xxs;
    padding: 6px 0 16px;
    line-height: 22px;
    font-weight: 400;
    font-style: normal;
    margin: $spacer-0;
    box-shadow: inset 0px -1px 0px #f0f0f0;
  }

  &_addOn {
    font-weight: 400;
  }

  &_common {
    max-width: 95%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &-nrs {
      &-columnWrap {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &-content-wrap {
        width: 100%;
        max-width: 520px;
        display: flex;
        flex-direction: column;
      }
      &-painLevel {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      &-optionsContainer {
        display: flex;
        padding-bottom: $spacer-7;
      }
      &-descriptionContainer {
        display: flex;
        flex-direction: column;
        width: 200px;

        &__p-top {
          font-size: $fontSize-md;
          font-weight: $fontWeight-lg;
          margin-bottom: 5px;
        }
        &__p-bottom {
          font-size: $fontSize-xxs;
        }
      }
      &-values-descriptors {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &-container {
      padding: 24px 0;
    }
    &-list {
      &-container {
        padding: 24px 0;
        justify-content: center;
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
      }

      &-input {
        max-width: 320px;
        width: 100%;

        &-container {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }

      &-title {
        text-align: left;
        margin-bottom: $spacer;
      }

      &-btn {
        margin-top: $spacer-5;
        display: flex;
      }

      &-block {
        margin-bottom: $spacer-5;
      }

      &-removeIcon {
        color: $color-red !important;
        cursor: pointer !important;
      }
    }

    &-treatment {
      border: 1px solid #f0f0f0;
      text-align: center;
      min-height: 140px;

      &--selected {
        border: 1px solid $color-primary;
      }

      &--error {
        border: 1px solid $color-orangeDark;
      }
    }

    &-treatment {
      margin-bottom: 33px;
    }

    &-btns {
      padding: 24px 0 23px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;

      &--horizontal {
        flex-direction: row;
        justify-content: center;
        column-gap: 8px;
      }
      &--noBorder {
        border: none;
      }
    }

    &-consent {
      padding: 24px 0 23px;
      row-gap: $spacer;
    }

    &-satisfaction {
      border: 1px solid $color-primary;
      text-align: center;
    }

    &-range {
      padding: 20px 0 21px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &--horizontal {
        flex-direction: row-reverse;
        justify-content: center;
      }

      &-description {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-label {
        font-weight: bold;
      }
    }

    &-slider {
      height: 133px;
    }

    &-slider--horizontal {
      width: 133px;
    }

    &-textarea-wrapper {
      padding: 24px;
    }

    &-no-preview {
      padding: 24px 0 23px;
    }

    &-period {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      &-input {
        max-width: 180px;
      }

      &-selector {
        width: 100%;
        max-width: 180px;
        text-align: left;
      }
    }

    &-text-or-skip {
      &-container {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  &_settings {
    width: 32%;
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;

    &_wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-title {
      padding-left: 24px;

      &--block {
        text-align: center;
        display: block;
        margin-bottom: 0;
      }
    }

    &-options {
      width: 100%;
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      line-height: normal;

      button {
        color: red;
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
      }
    }

    &-form {
      padding: 24px 18px;

      .item-without-label {
        margin-left: 102px;
      }
    }

    &-btn-wrapper {
      margin-bottom: 24px;
      display: flex;
      justify-content: right;
    }
    &-btn {
      border: none;
      box-shadow: none;
      color: $color-primary;
    }

    &-slider {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &-subTitle {
      display: flex;
      box-shadow: inset 0px -1px 0px #f0f0f0;
      margin-bottom: $spacer-5;
      font-size: $fontSize-xxs;
      font-weight: $fontWeight-lg;
      color: $color-dark;
      padding-bottom: $spacer;
    }
  }

  &_container {
    height: 0;
    min-height: 0;
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow-y: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_item {
    position: relative;
    margin-right: 60px;
    margin-left: 60px;
  }

  &_order {
    &__buttons {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      position: absolute;
      top: 0;
      right: -3.6em;
      z-index: 2;

      button {
        width: 40px;
        height: 40px;
        font-size: 24px;

        padding: 0;
      }

      &--hidden {
        display: none;
      }
    }
    &_leftPanel {
      button {
        width: $button-large;
        height: $button-large;
        font-size: $fontSize-xs;
      }
      &--top {
        display: flex;
        position: absolute;
        left: -3.6em;
        top: 0px;
        width: 40px;
        height: 40px;
        border-radius: 0px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        width: $button-large;
        height: $button-large;
        font-size: $fontSize-xs;
        border-color: #d9d9d9;
      }
      &--middle {
        display: flex;
        width: 40px;
        position: absolute;
        left: -3.6em;
        top: 50px;
        height: 40px;
        border-radius: 0px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        width: $button-large;
        height: $button-large;
        font-size: $fontSize-xs;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-color: #d9d9d9;
        &:hover {
          border-color: #40a9ff;
          cursor: pointer;
        }
      }
      &--bottom {
        display: flex;
        width: 40px;
        position: absolute;
        left: -3.6em;
        top: 100px;
        height: 40px;
        border-radius: 0px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        width: $button-large;
        height: $button-large;
        font-size: $fontSize-xs;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-color: #d9d9d9;
        &:hover {
          border-color: #40a9ff;
          cursor: pointer;
        }
      }
    }
  }
}
